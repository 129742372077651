import { Icon } from '@nrk/yr-icons';
import { INowcastPoint } from '../../model/nowcast';
import { precipitationPathDescriptionFromNowcastPoints } from '../../lib/formatGraphics';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { createNowcastScreenReaderSummary } from './helpers/summary';
import './NowGraph.scss';
import { Text } from '../Text/Text';

const DURATION_MINUTES = 90;

const X_AXIS_HEIGHT = 30;
const Y_AXIS_WIDTH = 20;
const GRAPH_WIDTH = 320;
const GRAPH_HEIGHT = 80;
const GRAPH_CONTENT_WIDTH = GRAPH_WIDTH;
const GRAPH_CONTENT_HEIGHT = GRAPH_HEIGHT - X_AXIS_HEIGHT;

interface IProps {
  points: INowcastPoint[];
}

// The now graph has fluid width and height equal to its parent element.
// The y axis width and x axis height is fixed, but every other dimension is fluid.
export function NowGraph(props: IProps) {
  const { points } = props;
  const translate = useTranslate();

  const d = precipitationPathDescriptionFromNowcastPoints({
    points,
    targetDuration: DURATION_MINUTES,
    width: GRAPH_CONTENT_WIDTH,
    height: GRAPH_CONTENT_HEIGHT
  });

  // Horizontal grid lines. We need to render them at a half pixel offset to ensure crisp rendering.
  // The last line is `- 0.5` to prevent it from rendering outside the SVG.
  const horizontalGridLines = [
    Math.round((50 / 3) * 0) + 0.5,
    Math.round((50 / 3) * 1) + 0.5,
    Math.round((50 / 3) * 2) + 0.5,
    Math.round((50 / 3) * 3) - 0.5
  ];

  return (
    <div className="now-graph" style={{ paddingBottom: X_AXIS_HEIGHT }} data-testid="now-graph">
      <div className="nrk-sr">{createNowcastScreenReaderSummary(points, translate)}</div>

      <div aria-hidden="true">
        <div className="now-graph__x-axis" style={{ height: X_AXIS_HEIGHT }}>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              {translate('grammar/now')}
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              15
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              30
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              45
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              60
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              75
            </Text>
          </div>
          <div className="now-graph__x-axis-tick">
            <Text size="5" color="secondary" className="now-graph__x-axis-label">
              {DURATION_MINUTES} {translate('units/time/minute/short')}
            </Text>
          </div>
        </div>

        <div className="now-graph__y-axis-container" style={{ width: Y_AXIS_WIDTH, height: GRAPH_CONTENT_HEIGHT }}>
          <div className="now-graph__y-axis">
            <div className="now-graph__y-axis-tick">
              <Icon className="now-graph__y-axis-symbol" id="icon-drop-full" />
            </div>

            <div className="now-graph__y-axis-tick">
              <Icon className="now-graph__y-axis-symbol" id="icon-drop-medium" />
            </div>

            <div className="now-graph__y-axis-tick">
              <Icon className="now-graph__y-axis-symbol" id="icon-drop-light" />
            </div>

            <div className="now-graph__y-axis-tick" />
          </div>
        </div>

        <div className="now-graph__graph-content">
          <svg
            className="now-graph__graph"
            width={GRAPH_CONTENT_WIDTH}
            height={GRAPH_CONTENT_HEIGHT}
            viewBox={`0 0 ${GRAPH_CONTENT_WIDTH} ${GRAPH_CONTENT_HEIGHT}`}
            preserveAspectRatio="none"
          >
            <defs>
              {/* We use clip-path to only render the graph above the lowest grid line */}
              <clipPath id="now-graph__curve-clip-path">
                <rect x="0" y="0" width={GRAPH_CONTENT_WIDTH} height={GRAPH_CONTENT_HEIGHT - 1} />
              </clipPath>
            </defs>
            <path
              className="now-graph__curve"
              data-testid="now-graph__curve"
              d={d}
              clipPath="url(#now-graph__curve-clip-path)"
            />

            <g className="now-graph__grid-lines">
              {horizontalGridLines.map(line => (
                <line key={line} x1={0} y1={line} x2="100%" y2={line} />
              ))}
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}
